<template>
  <v-layout max-width max-height style="background:#fff;">
    <div class=" flex-x-center  max-width mt-12">
      <div class=" flex-align-start mt-12">
        <div class=" flex-x-center">
          <div class=" flex-column flex-y-center pr-12 mr-8" style="width:250px;">
            <v-avatar color=" grey lighten-5" size="200">

              <!-- <img v-if="img_url" class="mr-0" :src="img_url" alt="avatar" /> -->
              <img v-if="userInfo.avatar" class="mr-0" :src="userInfo.avatar" alt="avatar">
              <img v-else class="mr-0" src="@/assets/head2.png" alt="avatar">

            </v-avatar>
            <span class="mt-3">{{ userInfo.realname||'-' }}</span>
            <span class=" text-h4 mt-4 mb-3 mr-3">{{ userInfo.roleName||'-' }}</span>

            <v-btn depressed outlined class=" max-width my-4" @click="showCutter=true">更换头像</v-btn>
            <avatar-cutter
              v-if="showCutter"
              return-type="url"
              @cancel="showCutter = false"
              @enter="uploadAvatar"
            />
            <v-btn depressed outlined class=" max-width" @click="dialog.show=true,$refs.form && $refs.form.reset()">修改密码</v-btn>
          </div>
          <!-- <div class=" flex-column">
                        <div class=" flex-align-end mb-4">
                            <span class=" text-h4 mr-3">马兴源</span>
                            <span>开发工程师</span>
                        </div>

                        <div class=" flex-y-center mb-4">
                            <span class="user-title">用户名：</span>
                            <span>maxingyuan</span>
                        </div>
                        <div class=" flex-y-center mb-4">
                            <span class="user-title">性别：</span>
                            <span>maxingyuan</span>
                        </div>
                        <div class=" flex-y-center mb-4">
                            <span class="user-title">邮箱：</span>
                            <span>maxingyuan.</span>
                        </div>
                        <div class=" flex-y-center mb-4">
                            <span class="user-title">用户名：</span>
                            <span>maxingyuan</span>
                        </div>
                        <div class=" flex-y-center mb-4">
                            <span class="user-title">性别：</span>
                            <span>maxingyuan</span>
                        </div>
                        <div class=" flex-y-center mb-4">
                            <span class="user-title">邮箱：</span>
                            <span>maxingyuan.</span>
                        </div>
                    </div> -->
        </div>

      </div>
    </div>
    <!-- <div class="max-height flex-justify-between">
          <div class="max-height  flex-0 flex-shrink-0" style="width:300px;border-right:1px solid #ddd;">
                <div class=" flex-column flex-center py-8" style="border-bottom:1px dotted #ddd;">
                    <v-avatar color="grey darken-3" size="130">
                        <img class="mr-0" src="@/assets/1.jpg" alt="avatar" />
                    </v-avatar>
                    <span class="mt-3">马兴源</span>
                </div>
                <div class="pl-10 pt-4">
                    <div class=" flex-y-center mb-2">
                        <span class="user-title">用户名：</span>
                        <span>maxingyuan</span>
                    </div>
                    <div class=" flex-y-center mb-2">
                        <span class="user-title">性别：</span>
                        <span>maxingyuan</span>
                    </div>
                    <div class=" flex-y-center mb-2">
                        <span class="user-title">邮箱：</span>
                        <span>maxingyuan.</span>
                    </div>

                </div>

          </div>
          <div class=" flex-1 overflow-auto">
              <span class=" text-h6 px-4 py-5">
                  参与研究项目（5）
              </span>
              <div>

              </div>
          </div>
      </div> -->
    <v-dialog v-model="dialog.show" width="500">
      <v-card>
        <v-form ref="form" v-model="valid" class="max-width" @submit.prevent="resetSubmit">
          <v-card-title class="font-weight-bold">修改密码</v-card-title>
          <v-card-text class=" pt-12 pb-6 px-12 body-1">
            <div>
              <v-text-field v-model="reset.oldPwd" required :rules="[v => !!v || '请输入原密码...']" prepend-icon="mdi-lock" placeholder="原密码" type="password" />
            </div>
            <div>
              <v-text-field v-model="reset.password" required :rules="[v => !!v || '请输入新密码...']" prepend-icon="mdi-lock" placeholder="新密码" type="password" />
            </div>
            <div>
              <v-text-field v-model="reset.confirmPassword" required :rules="[v => !!v || '请输入确认密码...',v => v===reset.password || '新密码与确认密码不一致...']" prepend-icon="mdi-lock" placeholder="确认新密码" type="password" />
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn width="80" class="mr-2" depressed :loading="btnLoading" @click="dialog.show = false">关闭</v-btn>
            <v-btn width="80" class depressed :loading="btnLoading" color=" primary" type="submit">确认</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <!-- 修改密码 end -->
  </v-layout>
</template>

<script>
import AvatarCutter from '@/components/avatar-cutter/avatar-cutter'
export default {
  components: { AvatarCutter },
  data() {
    return {
      dialog: {
        show: false,
        data: {}
      },
      img_url: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).avatar : '',
      userInfo: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {},
      showCutter: false,
      btnLoading: false,
      valid: true,
      reset: {}
    }
  },
  methods: {
    // 根据id获取用户信息
    getUserQuery() {
      const param = new URLSearchParams()
      param.append('id', this.userInfo.id)
      this.$http.get(this.$api.userQueryById, { data: param }).then(res => {
        const _e = JSON.parse(JSON.stringify(this.userInfo))
        _e.avatar = res.result.avatar
        localStorage.setItem('userInfo', JSON.stringify(_e))
        this.userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {}
      })
    },
    uploadAvatar(src) {
      const the = this
      const form = new FormData()
      form.append('file', this.dataURLtoFile(src, 'avatar.png'))
      form.append('head', 'head')
      // the.img_url = src
      // the.showCutter = false

      // the.user.img_url = src
      // form.append('user_id', this.$store.state.user.id)
      // form.append('access_token', this.$cookies.get('access_token'))

      // console.log(src)
      // console.log(this.dataURLtoFile(src, 'avatar.png'))
      the.$http.post(the.$api.uploadAvatar, { data: form }).then(() => {
        this.getUserQuery()
        this.$message.success('上传成功！')
        // the.$snackbar.start({
        //   text: res.message,
        //   color: '#4CAF50'
        // })
        // this.img_url=res.message
        // the.user.img_url = src
        the.showCutter = false
      })

      // the.user.img_url = src
      // form.append('user_id', this.$store.state.user.id)
      // form.append('access_token', this.$cookies.get('access_token'))
      // the.$http.post(the.$api.uploadHead, form, {
      //   onUploadProgress: (e) => {
      //     let complete = ((e.loaded / e.total * 100).toFixed(2) | 0)
      //   }
      // }).then((res) => {
      //   if (res.code === 0) {
      //     the.$snackbar.start({
      //       text: res.message,
      //       color: '#4CAF50'
      //     })
      //     the.user.img_url = src
      //     the.showCutter = false
      //   }
      // })
    },
    dataURLtoFile(dataurl, filename) {
      const arr = dataurl.split(','); const mime = arr[0].match(/:(.*?);/)[1]
      const bstr = atob(arr[1]); let n = bstr.length; const u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, { type: mime })
    },
    // 确认修改密码
    async resetSubmit() {
      if (this.$refs.form.validate()) {
        this.btnLoading = true
        this.$http.post(this.$api.changePassword, { data: { ...this.reset, username: this.userInfo.username }, type: 'application/x-www-form-urlencoded' }).then(res => {
          this.$message.success(res.result)
          this.btnLoading = false
          localStorage.removeItem('access_token')
          localStorage.removeItem('userInfo')
          return this.$router.replace({ path: '/login' })
        }).catch(() => {
          this.btnLoading = false
        })
      } else {
        return false
      }
    }
  }
}
</script>

<style>

</style>
