var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"c-avatar-cutter",on:{"dragstart":false}},[_c('div',{staticClass:"mask"}),_c('div',{staticClass:"container g-popup-box"},[_c('div',{staticClass:"_title"},[_vm._v("选择头像")]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"c-left",class:{'c-left--doing':_vm.imgURL}},[_c('div',{staticClass:"container-box",style:({
            width:((_vm.containerBoxData.width) + "px"),
            height:((_vm.containerBoxData.height) + "px"),
          })},[_c('img',{ref:"$img",attrs:{"src":_vm.imgURL}}),_c('div',{staticClass:"img-mask"}),(_vm.imgURL)?_c('div',{staticClass:"select-box",style:({
              top:((_vm.selectData.top) + "px"),
              left:((_vm.selectData.left) + "px"),
              width:((_vm.selectData.width) + "px"),
              height:((_vm.selectData.width) + "px"),
              'background-image':("url(" + _vm.imgURL + ")"),
              'background-position':((-_vm.selectData.left) + "px " + (-_vm.selectData.top) + "px"),
              'background-size':((_vm.containerBoxData.width) + "px " + (_vm.containerBoxData.height) + "px"),
            }),on:{"mousedown":function($event){return _vm.onMouseDown($event, 'move')}}},[_c('ul',{staticClass:"x-line"},[_c('li',{on:{"mousedown":function($event){$event.stopPropagation();return _vm.onMouseDown($event, 'stretch', 'top')}}}),_c('li'),_c('li'),_c('li',{on:{"mousedown":function($event){$event.stopPropagation();return _vm.onMouseDown($event, 'stretch', 'bottom')}}})]),_c('ul',{staticClass:"y-line"},[_c('li',{on:{"mousedown":function($event){$event.stopPropagation();return _vm.onMouseDown($event, 'stretch', 'left')}}}),_c('li'),_c('li'),_c('li',{on:{"mousedown":function($event){$event.stopPropagation();return _vm.onMouseDown($event, 'stretch', 'right')}}})]),_c('ul',{staticClass:"point"},[_c('li',{on:{"mousedown":function($event){$event.stopPropagation();return _vm.onMouseDown($event, 'stretch', 'top-left')}}}),_c('li',{on:{"mousedown":function($event){$event.stopPropagation();return _vm.onMouseDown($event, 'stretch', 'top-right')}}}),_c('li',{on:{"mousedown":function($event){$event.stopPropagation();return _vm.onMouseDown($event, 'stretch', 'bottom-left')}}}),_c('li',{on:{"mousedown":function($event){$event.stopPropagation();return _vm.onMouseDown($event, 'stretch', 'bottom-right')}}})]),_c('div',{staticClass:"cross"})]):_vm._e()])]),_c('div',{staticClass:"c-right"},[_c('div',{staticClass:"preview"},[_c('p',[_vm._v("预览")]),_c('canvas',{ref:"$canvas",class:{'canvas--doing':_vm.imgURL},attrs:{"height":"190","width":"190"}},[_vm._v(">")])]),_c('input',{staticClass:"btn-upload",attrs:{"value":!_vm.imgURL ? '选择图片' : '重新选择',"type":"button"}}),_c('input',{attrs:{"accept":"image/*","type":"file"},on:{"change":_vm.fileChange}})])]),_c('div',{staticClass:"c-btn-group"},[_c('input',{staticClass:"btn-cancel",attrs:{"type":"button","value":"取消"},on:{"click":_vm.onCancel}}),_c('input',{staticClass:"btn-enter",class:{'btn-enter--disable':!_vm.imgURL},attrs:{"type":"button","value":"确定"},on:{"click":_vm.onEnter}})])])])}
var staticRenderFns = []

export { render, staticRenderFns }